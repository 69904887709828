const vehicle_loading_type = {
    name: 'vehicle_loading_type',
    title: 'Тип загрузки транспорта',
    url: '/refs/vehicle_loading_type',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.vehicle_loading_type.list',
        read: 'v1.vehicle_loading_type.read',
        create: 'v1.vehicle_loading_type.create',
        update: 'v1.vehicle_loading_type.update',
        delete: 'v1.vehicle_loading_type.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
};

export {
    vehicle_loading_type,
};
